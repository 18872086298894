import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Checkbox, Input, Modal, Skeleton, Tag } from "antd";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StrapiClient } from "../../api/instance";
import { BrandsProps, PaginationProps } from "../../api/type";
import { axiosExtract, usePageBrake, useQuery, useScrollToSmooth, useWindowScroll } from "../../utils";

type Brand = {
	name: string;
	image: string;
	category: string;
};

const brands = [
	{
		name: "Lumina Beauty",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura della pelle",
	},
	{
		name: "Blossom Cosmetics",
		image: "[Immagine Unsplash]",
		category: "Trucco e cosmetici",
	},
	{
		name: "Pure Glow",
		image: "[Immagine Unsplash]",
		category: "Prodotti per il viso e il corpo",
	},
	{
		name: "Serene Skincare",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura della pelle",
	},
	{
		name: "Petal Perfumes",
		image: "[Immagine Unsplash]",
		category: "Profumi e fragranze",
	},
	{
		name: "Velvet Lips",
		image: "[Immagine Unsplash]",
		category: "Prodotti per le labbra",
	},
	{
		name: "Enchanting Eyes",
		image: "[Immagine Unsplash]",
		category: "Prodotti per gli occhi",
	},
	{
		name: "Silk Haircare",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura dei capelli",
	},
	{
		name: "Radiant Nails",
		image: "[Immagine Unsplash]",
		category: "Prodotti per la cura delle unghie",
	},
	{
		name: "Aura Wellness",
		image: "[Immagine Unsplash]",
		category: "Prodotti per il benessere e la cura del corpo",
	},
] satisfies Array<Brand>;

type BrandsPromiseProps = {
	meta: { pagination: PaginationProps };
	data: Array<{ id: number; attributes: BrandsProps }>;
};

function groupBrandsBy(brands: Array<Brand>, by: keyof Brand) {
	return brands.reduce<{ [key:string]: Array<Brand> }>((accumulator, brand) => {
		const groupedKey = brand[by];
			if (!accumulator[groupedKey]) {
				accumulator[groupedKey] = [];
			}
			accumulator[groupedKey].push(brand);
			return accumulator;
	}, {})
}

const brandCategories = Object.keys(groupBrandsBy(brands, "category"));

const Marchi = () => {
	const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
	const [inputWidth, setInputWidth] = useState(0);

	const handleFilterMenu = () => setIsFilterMenuOpen(!isFilterMenuOpen);
	const searchRef = useRef<HTMLDivElement>(null);
	const { y } = useWindowScroll();
	const { md } = usePageBrake(false);

	useScrollToSmooth(0)
	useEffect(() => {
		if (searchRef.current && y < (md ? 103 : 88)) {
			setInputWidth((prevWidth) => (prevWidth === (searchRef.current?.clientWidth ?? 0) ? prevWidth : searchRef.current?.clientWidth ?? 0));
		}
	}, [y, md]);

	//infinite data loading
	async function getBrandsList(
		limit: number,
		offset: number = 1,
	): Promise<{ rows: Array<{ id: number; attributes: BrandsProps }>; nextOffset: number }> {
		const { data: rows } = await axiosExtract(StrapiClient.get<BrandsPromiseProps>(
			`/brands?sort=id&populate=img&populate=brand_categories&pagination[page]=${offset}&pagination[pageSize]=${limit}&pagination[withCount]=true`,
		));
		return { rows, nextOffset: offset + 1 };
	}

	const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		["brands"],
		(ctx) => getBrandsList(20, ctx.pageParam),
		{
			getNextPageParam: (lastGroup) => lastGroup.nextOffset,
		},
	);

	const rows = useMemo(() => (data ? data.pages.flatMap((d) => d.rows) : []), [data]);
	const { query, setQuery, list } = useQuery(rows ?? []);

	const handleScroll = useCallback(async () => {
		if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight || !hasNextPage || isFetchingNextPage) {
			return;
		}
		await fetchNextPage();
	}, [fetchNextPage, hasNextPage, isFetchingNextPage]);

	useEffect(() => {
		window.addEventListener("scroll", () => handleScroll);
		return () => window.removeEventListener("scroll", () => handleScroll);
	}, [handleScroll]);
	//infinite data loading */

	if (isFetching && !isFetchingNextPage) {
		return (
			<section data-role="brand" className="m-4 xl:w-11/12 xl:mx-auto">
				<Skeleton active={true} className="!w-full mb-8" />
				<Skeleton.Button active={true} className="!w-full !h-6 mb-8" shape="square" />
				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
					{Array.from({ length: 12 }).map((_, i) => (
						<Skeleton.Button active={true} className="!w-full !h-72" shape="square" key={i} />
					))}
				</div>
			</section>
		);
	}

	return (
		<>
			<section data-role="brand" className="m-4 xl:w-11/12 xl:mx-auto">
				<div className="flex justify-between">
					<h2>Marchi</h2>
				</div>
				<p className="mb-4">
					Fragranze emozionali Cosmetici d&apos;altissima ricerca Bijoux dalla creatività esclusiva ....
					<strong>Prodotti selezionati</strong> che puntano all&apos;eccellenza per una clientela raffinata.
				</p>
				<div
					className={classNames({
						"bg-opacity-20 backdrop-blur-lg sticky top-[88px] md:top-[103px] z-40 p-4": y > (md ? 103 : 88),
					})}
					style={
						y > (md ? 103 : 88)
							? {
									marginRight: `-${(window.innerWidth - inputWidth) / 2}px`,
									marginLeft: `-${(window.innerWidth - inputWidth) / 2}px`,
								}
							: undefined
					}
				>
					<div
						className={classNames({
							"flex justify-center items-center gap-4": true,
							"mb-4 lg:mb-12": y < (md ? 103 : 88),
						})}
						ref={searchRef}
					>
						<Input
							placeholder="Ricerca un prodotto..."
							value={query}
							onChange={(e) => setQuery(e.currentTarget.value)}
							prefix={<MagnifyingGlassIcon width={24} height={24} />}
							size="large"
							className="transition-all"
						/>
					</div>
				</div>
				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
					{list
						.sort((a, b) => (a.attributes.name > b.attributes.name ? 1 : -1))
						.map((x) => (
							<div className="h-72 flex justify-center items-center rounded bg-white mb-2 relative overflow-hidden" key={x.attributes.name}>
								<div className="w-full h-full relative">
									<img
										src={x.attributes.img.data.attributes.url}
										alt={x.attributes.img.data.attributes.name}
										className="object-cover rounded-md hover:scale-110 transition-transform duration-500 w-full h-full"
									/>
									<div className="absolute top-2 right-1">
										<div className="flex gap-1">
											{x.attributes.brand_categories.data.map((category, i) => (
												<Tag color="green" key={i}>
													{category.attributes.name}
												</Tag>
											))}
										</div>
									</div>
									<div className="absolute bottom-0 inset-x-0 w-full p-2 bg-gradient-to-t from-zinc-100 to-transparent">
										<h3 className="text-right text-xl">{x.attributes.name}</h3>
									</div>
								</div>
							</div>
						))}
				</div>
			</section>
			<Modal
				title="Filtri"
				centered
				open={isFilterMenuOpen}
				onOk={handleFilterMenu}
				onCancel={handleFilterMenu}
				width={1000}
				styles={{ body: { overflowY: "auto", maxHeight: "calc(100vh - 200px)" } }}
			>
				<div className="w-full grid gap-4">
					<div className="rounded-md bg-white border">
						<div className="grid gap-2">
							{brandCategories.map((el) => (
								<Checkbox onChange={console.log} className="!ml-0" key={el}>
									{el}
								</Checkbox>
							))}
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Marchi;

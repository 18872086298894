import axios from "axios";
import { debugValue } from "../utils";

const StrapiClient = axios.create({
	baseURL: debugValue(import.meta.env.VITE_PUBLIC_STRAPI_API_URL),
	timeout: 1000,
	headers: {
		"Content-Type": "application/json",
        'Authorization': `Bearer ${import.meta.env.VITE_PUBLIC_STRAPI_API_TOKEN}`
	},
    
});

export { StrapiClient };

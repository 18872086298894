import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Button, Checkbox, Input, Modal, PaginationProps, Radio, Select, Skeleton, Space } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { ReactNode } from "react";
import { ProductProps } from "../../api/type";
import { axiosExtract, debugValue, useDisableBodyScroll, usePageBrake, useQuery, useScrollToSmooth, useWindowScroll } from "../../utils";
import { StrapiClient } from "../../api/instance";
import { CircularProgress } from "../../components/Circularprogress";
import { Card } from "../../components/Card";

type SortingOptionsVariants = "suggested" | "price-low-to-high" | "price-high-to-low";

const sortingOptions: Array<{ value: SortingOptionsVariants; label: string }> = [
	{ value: "suggested", label: "In evidenza" },
	{ value: "price-low-to-high", label: "Prezzo: in ordine crescente" },
	{
		value: "price-high-to-low",
		label: "Prezzo: in ordine decrescente",
	},
];

const PharmaCategory = [
	{
		category: "Dolore e Infiammazione",
		children: ["Analgesici e Antipiretici", "Antiinfiammatori non steroidei (FANS)"],
	},
	{
		category: "Risposte al Raffreddore e Sistema Respiratorio",
		children: ["Prodotti per il Raffreddore e l'Influenza"],
	},
	{
		category: "Salute Digestiva",
		children: ["Prodotti Gastrointestinali"],
	},
	{
		category: "Cura della Pelle",
		children: ["Prodotti Dermatologici", "Antifungini", "Prodotti per la Pelle e Sole"],
	},
	{
		category: "Supplementi e Nutrizione",
		children: ["Vitamine e Integratori"],
	},
	{
		category: "Allergie e Sistema Immunitario",
		children: ["Prodotti per l'Allergia"],
	},
	{
		category: "Salute degli Occhi e dell'Orecchio",
		children: ["Prodotti Oftalmici", "Prodotti per l'Orecchio"],
	},
	{
		category: "Salute Sessuale e Riproduttiva",
		children: ["Prodotti per la Salute Femminile", "Prodotti per la Salute Maschile"],
	},
	{
		category: "Salute Orale",
		children: ["Prodotti Orali"],
	},
	{
		category: "Stato d'Animo e Sonno",
		children: ["Sonniferi e Calmanti"],
	},
];

type ProductsPromiseProps = {
	meta: { pagination: PaginationProps };
	data: Array<{ id: number; attributes: ProductProps }>;
};

type FilterButtonProps = {
	categories: Array<{ key: string; label: string; children: ReactNode }>;
	onSubmit?(data: { sorting: SortingOptionsVariants; categories: Set<string>; adviced?: boolean | undefined }): void;
};

const FilterButton = (props: FilterButtonProps) => {
	const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

	const [filterStore, setFilterStore] = useState<{
		sorting: SortingOptionsVariants;
		categories: Set<string>;
		adviced?: boolean;
	}>({
		sorting: sortingOptions[0].value,
		categories: new Set(),
	});

	useDisableBodyScroll(isFilterMenuOpen);

	const handleFilterMenu = () => setIsFilterMenuOpen((isOpen) => !isOpen);

	function updateStore<T extends typeof filterStore, K extends keyof T>(field: K, payload: (data: T) => T[K] | T[K]) {
		return function (previousData: T): T {
			const newData = typeof payload === "function" ? payload(previousData) : payload;
			return { ...previousData, [field]: newData };
		};
	}

	function onSubmit() {
		props.onSubmit?.(filterStore);
		handleFilterMenu();
	}

	return (
		<>
			<Button onClick={handleFilterMenu} size="large">
				Seleziona filtri
			</Button>

			<Modal
				title="Filtri"
				centered
				open={isFilterMenuOpen}
				onOk={onSubmit}
				onCancel={handleFilterMenu}
				width={1000}
				styles={{ body: { overflowY: "auto", maxHeight: "calc(100vh - 200px)" } }}
			>
				<div className="w-full grid gap-4">
					<div className="rounded-md bg-white border">
						<h3 className="font-semibold mb-2">Ordina per</h3>
						<div className="grid gap-2">
							<Radio.Group defaultValue={filterStore.sorting} onChange={(e) => setFilterStore(updateStore("sorting", () => e.target.value))}>
								<Space direction="vertical">
									{sortingOptions.map((s) => (
										<Radio value={s.value} key={s.value}>
											{s.label}
										</Radio>
									))}
								</Space>
							</Radio.Group>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

const Prodotti = () => {
	const [filterStore, setFilterStore] = useState<{
		sorting: SortingOptionsVariants;
		categories: Set<string>;
		adviced?: boolean;
	}>({
		sorting: sortingOptions[0].value,
		categories: new Set(),
	});

	const [inputWidth, setInputWidth] = useState(0);
	const searchRef = useRef<HTMLDivElement>(null);
	const { y } = useWindowScroll();
	const { md } = usePageBrake(false);

	useScrollToSmooth(0)

	useEffect(() => {
		if (searchRef.current && y < (md ? 103 : 88)) {
			setInputWidth((prevWidth) => (prevWidth === (searchRef.current?.clientWidth ?? 0) ? prevWidth : searchRef.current?.clientWidth ?? 0));
		}
	}, [y, md]);

	async function getProducList(
		limit: number,
		offset: number = 1,
	): Promise<{ rows: Array<{ id: number; attributes: ProductProps }>; nextOffset: number }> {
		const rows = await axiosExtract(
			StrapiClient.get<ProductsPromiseProps>(
				`/products?sort=name&populate=img&pagination[page]=${offset}&pagination[pageSize]=${limit}&pagination[withCount]=true`,
			),
		);
		return { rows: rows.data, nextOffset: offset + 1 };
	}

	const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		["products"],
		(ctx) => getProducList(20, ctx.pageParam),
		{
			getNextPageParam: (lastGroup) => lastGroup.nextOffset,
		},
	);

	const rows = useMemo(() => (data ? data.pages.flatMap((d) => d.rows) : []), [data]);

	const { isReady, list, query, setQuery } = useQuery(rows ?? []);

	function sortArray<T>(array: T[], compareFn: (a: T, b: T) => number): T[] {
		return [...array].sort(compareFn);
	}

	function getDefaultPrice(data: ProductsPromiseProps["data"][number]) {
		if (data.attributes.discount_price) return data.attributes.discount_price;
		return data.attributes.price;
	}

	function onChangeOrder(item: SortingOptionsVariants): void {
		setFilterStore((prev) => ({ ...prev, sorting: item }));
	}

	const sortedList = useMemo(() => {
		switch (filterStore.sorting) {
			case "suggested":
				return list;
			case "price-high-to-low":
				return sortArray(list, (a, b) => getDefaultPrice(b) - getDefaultPrice(a));
			case "price-low-to-high":
				return sortArray(list, (a, b) => getDefaultPrice(a) - getDefaultPrice(b));
			default:
				return list;
		}
	}, [filterStore.sorting, list]);

	const mapCollapseData = PharmaCategory.map((category) => ({
		key: category.category,
		label: category.category,
		children: (function RenederBlock() {
			return (
				<div className="grid gap-2">
					{category.children?.map((name) => (
						<Checkbox onChange={console.log} className="!ml-0" value={name} key={name}>
							{name}
						</Checkbox>
					))}
				</div>
			);
		})(),
	}));

	const handleScroll = useCallback(async () => {
		if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight || !hasNextPage || isFetchingNextPage) {
			return;
		}
		await fetchNextPage();
	}, [fetchNextPage, hasNextPage, isFetchingNextPage]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [handleScroll]);

	if (isFetching && !isFetchingNextPage) {
		return (
			<section data-role="brand" className="m-4 xl:w-11/12 xl:mx-auto">
				<Skeleton active={true} className="!w-full mb-8" />
				<Skeleton.Button active={true} className="!w-full !h-6 mb-8" shape="square" />
				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
					{Array.from({ length: 12 }).map((_, i) => (
						<Skeleton.Button active={true} className="!w-full !h-72" shape="square" key={i} />
					))}
				</div>
			</section>
		);
	}

	return (
		<section className="m-4 xl:w-11/12 xl:mx-auto products">
			<h2 className="mb-4">Prodotti</h2>
			<div
				className={classNames({
					"bg-opacity-20 backdrop-blur-lg sticky top-[88px] md:top-[103px] z-40 p-4": y > (md ? 103 : 88),
				})}
				style={
					y > (md ? 103 : 88)
						? {
								marginRight: `-${(window.innerWidth - debugValue(inputWidth)) / 2}px`,
								marginLeft: `-${(window.innerWidth - debugValue(inputWidth)) / 2}px`,
							}
						: undefined
				}
			>
				<div
					className={classNames({
						"grid lg:grid-cols-2 items-center gap-4 relative": true,
						"mb-4 lg:mb-12": y < (md ? 103 : 88),
					})}
					ref={searchRef}
				>
					<div className="flex gap-2">
						<Input
							placeholder="Ricerca un prodotto..."
							value={query}
							onChange={(e) => setQuery(e.currentTarget.value)}
							prefix={isReady() || query === "" ? <MagnifyingGlassIcon width={24} height={24} /> : <CircularProgress />}
							size="large"
							className="grow hover:absolute hover:inset-x-0 hover:w-full md:!static"
						/>
						<div className="lg:hidden flex justify-end items-center shrink">
							<FilterButton categories={mapCollapseData} onSubmit={(data) => onChangeOrder(data.sorting)} />
						</div>
					</div>

					<div className="hidden lg:flex gap-1 justify-end items-center">
						<p>Ordina per:</p>
						<Select
							size="large"
							placeholder="ordina per"
							onChange={onChangeOrder}
							options={sortingOptions}
							value={filterStore.sorting}
							bordered={false}
							disabled={sortedList?.length === 0}
							className="text-sacramento-600"
							popupMatchSelectWidth={false}
						/>
					</div>
				</div>
			</div>
			<div className="grid lg:grid-cols-12 gap-4">
				<div className="lg:col-span-12">
					<div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 md:justify-evenly gap-8 md:gap-4">
						{sortedList?.map((x) => (
							<Card
								key={x.attributes.name}
								imageSrc={x.attributes.img.data.attributes.url}
								imageAlt={x.attributes.img.data.attributes.name}
								discountPrice={x.attributes.discount_price ?? undefined}
								price={x.attributes.price}
								title={x.attributes.name}
								id={String(x.id)}
							/>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Prodotti;
